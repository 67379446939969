<template>
    <div class="loginBackgroundAyaPay visbyRoundFont" style="">
        <div style="padding: 16px; padding-top: 100px; padding-bottom: 100px;">
            <img src="https://s3iconimages.mymedicine.com.mm/mymedLogo.svg" alt="">
        </div>
        <div style="display: flex;flex-direction: column; justify-content: center;  align-items: center;">
            <div style="color: #6b6b6b;">All Set...</div>
            <div style="font-size: 32px; font-weight: 600; margin-bottom: 40px;">Building Your Profile</div>
            <v-progress-circular
                :rotate="180"
                :size="150"
                :width="5"
                color="#4aaaf2"
                indeterminate
            >
                <img src="https://s3iconimages.mymedicine.com.mm/buildingProfile.gif" style="height: 100px; margin: 40px 0;" alt="">
            </v-progress-circular>
        </div>
        <div style="padding: 24px; ">
      </div>
    </div>
</template>

<script>

import axios from "axios";
import languageChangePart from './languageChangePart.vue';
export default {
  components: { languageChangePart },
  data() {
    return {
        ayaPay: false,
        otpLoading: false,
        phone_number: '',
        platform: ''
    };
  },

  methods: {
    async takeMeToLogin() {
      if(this.platform == 'AYA') {
        this.otpLoading = true;
        try {
          await this.sendOTP();
        } catch (error) {
          alert('Something went wrong');
          console.log(error);
        }
      } else {
        this.$router.push({
            name: 'Verify'
        })
      }
    },
    async sendOTP() {
      try {
        this.otpLoading = true;
        await axios.post(process.env.VUE_APP_BACKEND_URL+"/sendOTP",{
          phoneNumber: this.phone_number,
        });
        this.otpLoading = false;
        this.$router.push({
            name: "VerifyOTP",
            params: {
              phoneNumber: this.phone_number, 
            }
          });
        } catch(error) {
        console.log(error);
      }

    },
    calculateBackgroundClass() {
        if(this.ayaPay) {
            return 'loginBackgroundAyaPay visbyRoundFont'
        } else {
            return 'loginBackground visbyRoundFont'
        }
    }
  },
  mounted() {
    this.currentCustomer = this.$cookies.get("customerToken");
    if(this.$store.state.locale !== "")
    {
      this.$i18n.locale =  this.$store.state.locale;
    }
    else
    {
      this.$i18n.locale = "mm";
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    }
    this.Language = this.$i18n.locale;
    var access = this.$cookies.get("access");
    if(this.currentCustomer){

        var verifyCustomerTokenData = {
            "token" : this.currentCustomer,
            "typeOfUser" : "CUSTOMER"
        }
        axios.post(process.env.VUE_APP_BACKEND_URL + "/verifyCustomerToken",verifyCustomerTokenData)
        .then((verifyCustomerTokenDataResponse) => {
          this.$router.push({
            path: "/customer/teleconsultationHome",
          });
        })
        .catch((verifyCustomerTokenDataError)=>{
          this.$cookies.remove("customerToken");
          this.$cookies.remove("access");
        });
    }
    else{
      this.$cookies.remove("customerToken");
      this.$cookies.remove("access");
    }
  },

};
</script>

<style scoped>
.v-avatar {
  display: inline-flex;
  align-items: center;
}
.loginBackground {
  /* background: url('https://s3iconimages.mymedicine.com.mm/Rectangle 3.png'); */
  background: linear-gradient(to bottom right, #d9f2f5, #fcd2db);
  min-height: 100vh;
  background-size: cover;
}
.loginBackgroundAyaPay {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  background: linear-gradient(to bottom right, #d9f3f5 19%,#fff 49%, #fcd2db 113%);

  /* background: url('https://s3iconimages.mymedicine.com.mm/ayaPayBackground.png'); */
  min-height: 100vh;
  background-size: cover;
}
.doctorBackground {
  background: url('https://s3iconimages.mymedicine.com.mm/3dDoctors.svg');
  height: 300px;
  background-size: cover;
  /* border-radius: 0 0 40px 40px; */
}
.font_size_12_400 {
  font-size: 14px;
  font-weight: 400;
}
.mx-input {
  height: 40px !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
  .custom-button {
  text-transform: none;
  border-radius: 8px;
  font-size: 18px; letter-spacing: 0;
}
/* @media (min-width: 800px){
  .loginClass{
   width: 800px !important;
   margin-left: auto !important ;
    margin-right: auto !important ;

  }
  .MobileNumber{
    margin-top:14px !important
  }
  } */
</style>
